import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as AuthActions } from '~/store/modules/auth/ducks';
import { Creators as ModeColor } from '~/store/modules/darkMode/ducks';
import {
  CenteredFlexBox,
  ChangeColorModeSwitcherGrid,
  CustomBox,
  CustomBoxContentGrid,
  CustomIconButton,
  DarkColorModeSwitcherIcon,
  ExitButton,
  LightColorModeSwitcherIcon,
} from './styles';
import LogoDark from '~/assets/images/FLINSIDER_BRANCO.png';
import LogoWhite from '~/assets/images/Insider_Logo_Fundo_Claro.png';
// import LogoDark from '~/assets/images/LOGO_PLAT10+_1.png';
// import LogoWhite from '~/assets/images/LOGO_PLAT10+_2.png';
import HeaderPng from '~/assets/images/header_insider.png';

export default function ConferenceHeader({ vip }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.profile);
  const paymentLink = `${
    process.env.REACT_APP_CENTRAL_URL
  }&email=${encodeURIComponent(user.email)}`;
  const handleLogout = () => {
    dispatch(AuthActions.signOut());
  };

  const { darkMode } = useSelector((state) => state.darkMode);

  const handleDarkMode = () => {
    if (darkMode) {
      dispatch(ModeColor.setLightMode());
    } else {
      dispatch(ModeColor.setDarkMode());
    }
  };

  return (
    <CustomBox width={1} darkMode={darkMode}>
      <CustomBoxContentGrid container alignItems="center">
        <>
          <Grid item xs={12} sm={6} lg={8}>
            <CenteredFlexBox>
              <img
                src={darkMode ? LogoDark : LogoWhite}
                alt=""
                style={{
                  maxWidth: '391px',
                  margin: '1rem 0',
                  padding: '10px 45px',
                }}
              />
            </CenteredFlexBox>
          </Grid>

          <Grid item xs={12} sm={2} lg={1}>
            <CenteredFlexBox>
              <ExitButton darkMode={darkMode} onClick={handleLogout}>
                Sair
              </ExitButton>
            </CenteredFlexBox>
          </Grid>
          <Grid item xs={12} sm={2} lg={1}>
            <Grid container justify="center">
              <ChangeColorModeSwitcherGrid item xs={5} sm={12} lg={12}>
                <Grid container justify="center">
                  <Grid item xs={6}>
                    <CustomIconButton
                      onClick={handleDarkMode}
                      active={!darkMode}
                    >
                      <DarkColorModeSwitcherIcon darkMode={darkMode}>
                        dark_mode
                      </DarkColorModeSwitcherIcon>
                    </CustomIconButton>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomIconButton
                      onClick={handleDarkMode}
                      active={darkMode}
                    >
                      <LightColorModeSwitcherIcon darkMode={darkMode}>
                        light_mode
                      </LightColorModeSwitcherIcon>
                    </CustomIconButton>
                  </Grid>
                </Grid>
              </ChangeColorModeSwitcherGrid>
            </Grid>
          </Grid>
        </>
      </CustomBoxContentGrid>
      {/* <img src={HeaderPng} alt="" className="header-belts" /> */}
    </CustomBox>
  );
}

ConferenceHeader.propTypes = {
  countdownId: PropTypes.number,
  vip: PropTypes.bool,
};

ConferenceHeader.defaultProps = {
  countdownId: 0,
  vip: false,
};
