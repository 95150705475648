import styled from 'styled-components';
import { Button, Grid, Typography } from '@material-ui/core';
import { Timer } from '@material-ui/icons';

export const GridCountDown = styled(Grid)`
  max-width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 970px) {
    width: 100%;
  }
`;

export const CountDownNumber = styled.div`
  font-family: Orbitron;
  font-size: 23px;
  font-weight: 400;
  color: white;

  @media (max-width: 960px) {
    padding: 9px;
    font-size: 23px;
  }
`;

export const CountDownLabel = styled(Typography)`
  font-family: Orbitron;
  font-size: 15px;
  color: #47addc;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
export const CustomTimerIcon = styled(Timer)`
  color: ${(props) => (props.darkMode ? '#efefee' : '#000000')};
  font-size: 2.5em;
`;
export const Label = styled.span`
  font-family: Orbitron;
  font-size: 13px;
  color: ${(props) => (props.darkMode ? '#efefee' : '#000000')};
  line-height: 2;
  text-indent: 0.3em;
  position: relative;
  font-weight: bold;
  text-align: start;

  @media (max-width: 960px) {
    margin-right: 0;
  }

  @media (max-width: 600px) {
    font-size: 24px;
  }
  @media (max-width: 360px) {
    font-size: 22px;
  }
`;

export const CountdownButton = styled(Button)`
  background-color: #cccc;
  height: 63px;
  color: #848483;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 30px;
  border-radius: 10px;
`;
