import React, { useEffect } from 'react';
import io from 'socket.io-client';
import copy from 'copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Tabs, Typography } from '@material-ui/core';
import {
  Assignment,
  Cached,
  DateRange,
  EmojiEvents,
  GroupAdd,
  Info,
  NearMe,
  Tv,
} from '@material-ui/icons';
import { format, parseISO } from 'date-fns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { toast } from 'react-toastify';
import Icon from '@material-ui/core/Icon';
import Header from './header';
import Footer from './footer';
import { Creators as ModalActions } from '~/store/modules/modal/ducks';
import FileQuestion from './components/Questions/file';
import MultiAnswerQuestion from './components/Questions/multiAnswer';
import MultiChoiceQuestion from './components/Questions/multiChoice';
import SingleChoiceQuestion from './components/Questions/singleChoice';
import InstructionsComponent from './components/Instructions';
import LaunchPlanComponent from './components/LaunchPlan';
import {
  EventButton,
  SideBar,
  MainContent,
  HrBarGrid,
  StyledQuestionDate,
  CenteredGrid,
  TabButton,
  SideButton,
  SideBox,
  GridEventButton,
} from './styles';
import PointsToast from '~/components/PointsToast';
import Resources from '~/pages/Lobby/components/Resources';
import { Creators as ConferenceActions } from '~/store/modules/conference/ducks';
import { Creators as AnswerActions } from '~/store/modules/answer/ducks';
import { Creators as PointsActions } from '~/store/modules/points/ducks';
import { Creators as InteractionActions } from '~/store/modules/interaction/ducks';
import { Creators as PresencesAction } from '~/store/modules/presence/ducks';
import CopyAffiliateLinkModal from '~/pages/Lobby/components/CopyAffiliateLinkModal';
import UpgradedModal from '~/pages/Lobby/components/UpgradedModal';
import WarmupRoomsModal from '~pages/Lobby/components/WarmupRooms';
import UserRanking from '~/pages/Lobby/components/UserRanking';
import { Creators as AuthActions } from '~/store/modules/auth/ducks';
import Countdown from '~/pages/Lobby/components/Countdown';
import UserInfo from './components/UserInfo';

const TOGGLE_COMPONENT_EVENT = 'toggle';
const SOCKET_SERVER_URL = process.env.REACT_APP_API;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box p={3}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
};

const slidePages = [
  { label: 'Faixas-Azul', page: 3 },
  { label: 'Faixas-Verde', page: 22 },
  { label: 'Faixas-Marrom', page: 40 },
  { label: 'Faixas-Preta', page: 52 },
];

const slideUrlBase =
  'https://docs.google.com/presentation/d/e/2PACX-1vTyp75CcoEQz19yj4FDHVAjv9Zy87NnQgryaPu6Qmv--USIBT5_Ebu8b30qUbUk0RH8O3IxOpOnHFOP/embed?start=false&loop=true&delayms=60000';

export default function ManageConference() {
  const dispatch = useDispatch();
  const COPY_AFFILIATE_LINK_MODAL = 'COPY_AFFILIATE_LINK_MODAL';
  const UPGRADE_MODAL = 'UPGRADE_MODAL';
  const conference = useSelector((state) => state.conference);
  const { darkMode } = useSelector((state) => state.darkMode);
  const { answers } = useSelector((state) => state.answers);
  const { components, current: conferenceConfig } = conference;
  let countdownComponentId = 0;
  let pdfComponent = null;
  const [tabValue, setTabValue] = React.useState(0);
  const [slide, setSlid] = React.useState(1);
  const [slideUrl, setSlidUrl] = React.useState(slideUrlBase);
  const activeModal = useSelector((state) => state.modal.active);
  const questionLoading = useSelector((state) => state.answers.loading);

  const resourcesLists =
    components.filter((item) => !!item.resourceId && item.active) || [];

  const affiliateComponent =
    components.find((item) => item.affiliate_button && item.active) || null;

  const questionsComponents = components.filter(
    (component) =>
      component.active &&
      !!component.question &&
      !!component.question.items.length
  );

  const sideMenuButtons = components.filter(
    (component) => component.active && component.side_button
  );

  const buttonsList = components.filter(
    (component) =>
      component.active &&
      !component.side_button &&
      component.saleLetterId &&
      !component.pdf_button
  );

  const launchPlanModules = components.filter((component) => {
    if (component.pdf_button && component.active) {
      pdfComponent = component;
    }

    return component.launchPlanId && !component.pdf_button && component.active;
  });

  const componentConferenceButton = components.find(
    (component) => component.active && !!component.conferenceButtonId
  );

  const warmupRoomComponent = components.find(
    (component) => component.active && !!component.warmupRoomId
  );

  const user = useSelector((state) => state.user.profile);

  const getQuestionItemAnswer = (questionItemId) => {
    if (answers) {
      return answers.find((item) => {
        return item.question_item_id === questionItemId;
      });
    }
    return null;
  };

  const saveAnswer = (answer) => {
    dispatch(AnswerActions.answerSaveRequest(answer));
  };

  const handlePointsToast = (points) => {
    return toast(<PointsToast points={points} />);
  };

  useEffect(() => {
    dispatch(AnswerActions.answersListRequest({ userId: user.id }));
    dispatch(ConferenceActions.componentsRequest());
    dispatch(ConferenceActions.fetchConferenceConfigRequest());
  }, [dispatch]);

  useEffect(() => {
    const socketClient = io.connect(SOCKET_SERVER_URL);
    socketClient.on(TOGGLE_COMPONENT_EVENT, ({ id, active, featured }) => {
      dispatch(ConferenceActions.componentToggle({ id, active }));
    });

    socketClient.on('connect', () => {
      socketClient.emit('join', { id: user.id });
    });

    socketClient.on('points', (data) => {
      handlePointsToast(`${data.points} pontos`);
    });

    socketClient.on('upgrade', (data) => {
      const { tag } = data;
      dispatch(
        ModalActions.openModal({
          name: UPGRADE_MODAL,
          params: { tag },
        })
      );
    });
  }, [dispatch]);

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  const handleSlidChange = (event, value) => {
    setSlidUrl(`${slideUrlBase}&slide=${value}`);
    setSlid(value);
  };

  if (components.length) {
    components.map((component) => {
      if (component.countdownId) {
        countdownComponentId = component.active ? component.countdownId : 0;
      }
    });
  }

  const renderQuestionComponents = (questionItem) => {
    const answerItem = getQuestionItemAnswer(questionItem.id);
    const textSubmitButton = answerItem ? 'RESPONDIDO' : 'ENVIAR';
    const componentsList = {
      Evidence: (
        <FileQuestion
          questionItem={questionItem}
          handleSubmitAnswer={saveAnswer}
          textSubmitButton={textSubmitButton}
          answerItem={answerItem}
          loading={questionLoading}
        />
      ),
      MultiAnswer: (
        <MultiAnswerQuestion
          questionItem={questionItem}
          handleSubmitAnswer={saveAnswer}
          textSubmitButton={textSubmitButton}
          answerItem={answerItem}
          loading={questionLoading}
        />
      ),
      SingleChoice: (
        <SingleChoiceQuestion
          questionItem={questionItem}
          handleSubmitAnswer={saveAnswer}
          textSubmitButton={textSubmitButton}
          answerItem={answerItem}
          loading={questionLoading}
        />
      ),
      MultiChoice: (
        <MultiChoiceQuestion
          questionItem={questionItem}
          handleSubmitAnswer={saveAnswer}
          textSubmitButton={textSubmitButton}
          answerItem={answerItem}
          loading={questionLoading}
        />
      ),
    };

    return componentsList[questionItem.kind]
      ? componentsList[questionItem.kind]
      : '';
  };

  const formatQuestionDate = (date) => {
    return format(parseISO(date), `dd 'de' MMMM 'às 'HH mm`, {
      locale: ptBRLocale,
    });
  };

  const handlePointClick = (componentId, kind = 'component') => {
    dispatch(PointsActions.pointRequest({ componentId, kind }));
  };

  const handleInteractionClick = (componentId) => {
    dispatch(InteractionActions.interactionRequest({ componentId }));
  };

  const handleEventButtonClick = () => {
    if (componentConferenceButton.point_on_click) {
      handlePointClick(componentConferenceButton.id);
    } else {
      handleInteractionClick(componentConferenceButton.id)
    }
    dispatch(
      PresencesAction.presenceRequest({
        componentId: componentConferenceButton.id,
      })
    );
  };

  const handleOpenAffiliateLinkModal = () => {
    dispatch(ModalActions.openModal({ name: COPY_AFFILIATE_LINK_MODAL }));
  };

  const handleLogout = () => {
    dispatch(AuthActions.signOut());
  };

  const handleCloseModal = () => {
    dispatch(ModalActions.closeModal());
  };

  const handleCopy = () => {
    copy(user.affiliate_link);
    toast.success('Link copiado com sucesso.');
    handleCloseModal();
  };

  const welcomeColor = () => {
    if (darkMode) {
      return '#fefefe';
    }
    return '#323637';
  };

  const bgColor = () => {
    if (darkMode) {
      return '#1F3465';
    }

    return '#ACB8C3';
  };

  return (
    <Box width={1} style={{ 'min-height': '100vh', background: bgColor() }}>
      {Object.keys(user).length && (
        <>
          <Header vip={user.vip} />
          {!!affiliateComponent &&
            activeModal === COPY_AFFILIATE_LINK_MODAL && (
              <CopyAffiliateLinkModal
                link={user.affiliate_link}
                handleCopy={handleCopy}
                handleCloseModal={handleCloseModal}
              />
            )}

          {activeModal === UPGRADE_MODAL && (
            <UpgradedModal handleLogout={handleLogout} />
          )}
          <UserInfo user={user} welcomeColor={welcomeColor} />
          <GridEventButton container alignItems="flex-end">
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              style={{ 'margin-bottom': '10px' }}
            >
              {!!componentConferenceButton && (
                <Box mb={2}>
                  <EventButton
                    variant="contained"
                    className="conferenceButton"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      user.conference_link ||
                      componentConferenceButton.conference_button
                        .button_fallback_link
                    }
                    onClick={() => handleEventButtonClick()}
                  >
                    {componentConferenceButton.conference_button.button_text}
                  </EventButton>
                </Box>
              )}

              {!!warmupRoomComponent && (
                <Box my={2}>
                  <EventButton
                    variant="contained"
                    color="primary"
                    className="conferenceButton"
                    onClick={() =>
                      dispatch(ModalActions.openModal('CONFERENCE_MODAL'))
                    }
                  >
                    ESTAMOS AO VIVO COM O AQUECIMENTO DO FL INSIDER
                  </EventButton>
                </Box>
              )}

              {buttonsList.map((buttonComponent) => (
                <Box mb={2} key={buttonComponent.id}>
                  <EventButton
                    variant="contained"
                    color="primary"
                    className="conferenceButton"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={buttonComponent.sale_letter.cta_link}
                    bgcolor={buttonComponent.sale_letter.background_color}
                    iconcolor={buttonComponent.sale_letter.icon_color}
                    fontcolor={buttonComponent.sale_letter.text_color}
                    hvrcolor={buttonComponent.sale_letter.background_color}
                    startIcon={<Icon>{buttonComponent.sale_letter.icon}</Icon>}
                    onClick={() =>
                      buttonComponent.point_on_click
                        ? handlePointClick(buttonComponent.id)
                        : handleInteractionClick(buttonComponent.id)
                    }
                  >
                    {buttonComponent.sale_letter.cta_text}
                  </EventButton>
                </Box>
              ))}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item>
                  {!!countdownComponentId && (
                    <Countdown id={countdownComponentId} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </GridEventButton>
          <CenteredGrid container justify="center" style={{ padding: '1rem' }}>
            <MainContent item xs={12} sm={12} md={8} lg={8}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                style={{ paddingTop: '1rem' }}
              >
                <TabButton
                  display
                  label={
                    <div className="tab-icon">
                      <Info />
                      <span>MENU INICIAL</span>
                    </div>
                  }
                />
                <TabButton
                  display={false}
                  label={
                    <div className="tab-icon">
                      <Cached />
                      <span>ATIVIDADES</span>
                    </div>
                  }
                />
                <TabButton
                  display
                  label={
                    <div className="tab-icon">
                      <Assignment />
                      <span>MATERIAIS</span>
                    </div>
                  }
                />
                {/* <TabButton
                  display={conferenceConfig.pointEnabled}
                  label={
                    <div className="tab-icon">
                      <EmojiEvents />
                      <span>SUA PONTUAÇÃO</span>
                    </div>
                  }
                /> */}
                {/* <TabButton
                  display
                  label={
                    <div className="tab-icon">
                      <Tv />
                      <span>TROCAS DE FAIXA E GRAU</span>
                    </div>
                  }
                /> */}
              </Tabs>
              <HrBarGrid>
                <hr width="90%" />
              </HrBarGrid>
              <TabPanel value={tabValue} index={0}>
                <InstructionsComponent
                  conferenceConfig={{
                    faq: conferenceConfig.faq || '',
                    instructions: conferenceConfig.instructions || '',
                  }}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Grid container justify="center">
                  <Grid item xs={12} sm={12} md={12}>
                    <>
                      {questionsComponents.length ? (
                        <>
                          {questionsComponents.map((component) => (
                            <Grid key={component.id}>
                              <Typography component="span" variant="h3">
                                <b>{component.name}</b>
                              </Typography>
                              <Box pt={3}>
                                <StyledQuestionDate variant="body2">
                                  <b>
                                    {formatQuestionDate(component.updatedAt)}
                                  </b>
                                </StyledQuestionDate>
                              </Box>
                              <Box pt={3}>
                                {component.question.items.map(
                                  (questionItem) => (
                                    <Box key={questionItem.id} mb={6}>
                                      {renderQuestionComponents(questionItem)}
                                    </Box>
                                  )
                                )}
                              </Box>
                              <hr />
                            </Grid>
                          ))}
                        </>
                      ) : (
                        <Box mt={3} textAlign="center">
                          <Typography>
                            Nenhuma atividade ativa no momento
                          </Typography>
                        </Box>
                      )}
                    </>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Resources
                  resourcesListsComponents={resourcesLists}
                  handlePointClick={handlePointClick}
                />
              </TabPanel>
              {!!conferenceConfig.pointEnabled && (
                <TabPanel value={tabValue} index={3}>
                  <UserRanking />
                </TabPanel>
              )}
              {/* Conteúdo da aba sacadas que foi removido*/}
              {/*<TabPanel value={tabValue} index={4}>*/}
              {/*  <LaunchPlanComponent*/}
              {/*    components={launchPlanModules}*/}
              {/*    pdfComponent={pdfComponent}*/}
              {/*  />*/}
              {/*</TabPanel>*/}
              <TabPanel value={tabValue} index={4}>
                <Box>
                  {slidePages.map((slidePage) => {
                    return (
                      <button
                        onClick={() => handleSlidChange(this, slidePage.page)}
                        style={{
                          marginRight: 5,
                          marginBottom: 5,
                          paddingRight: 10,
                          paddingLeft: 10,
                          paddingTop: 4,
                          paddingBottom: 4,
                          border: 'none',
                          backgroundColor: '#e7e7e7',
                          color: 'black',
                          fontSize: 16,
                          cursor: 'pointer',
                          borderRadius: 4,
                        }}
                        webkitallowfullscreen={true}
                      >
                        {slidePage.label}
                      </button>
                    );
                  })}
                  <iframe
                    title="belt"
                    src={slideUrl}
                    frameBorder="0"
                    width="100%"
                    height="600"
                    key={slide}
                    allowFullScreen="true"
                    mozallowfullscreen="true"
                    webkitallowfullscreen="true"
                  />
                </Box>
              </TabPanel>
            </MainContent>
            <SideBar item xs={12} sm={12} md={4} lg={4}>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems={window.innerWidth >= 960 ? 'flex-end' : 'center'}
              >
                {sideMenuButtons.map((sideButton) => (
                  <Grid item key={sideButton.id}>
                    <SideButton
                      target="_blank"
                      href={sideButton.sale_letter.cta_link}
                      rel="noopener noreferrer"
                      variant="contained"
                      color="primary"
                      bgcolor={sideButton.sale_letter.background_color}
                      iconcolor={sideButton.sale_letter.icon_color}
                      fontcolor={sideButton.sale_letter.text_color}
                      hvrcolor={sideButton.sale_letter.background_color}
                      startIcon={<Icon>{sideButton.sale_letter.icon}</Icon>}
                      onClick={() =>
                        sideButton.point_on_click
                          ? handlePointClick(sideButton.id)
                          : handleInteractionClick(sideButton.id)
                      }
                    >
                      {sideButton.sale_letter.cta_text.toUpperCase()}
                    </SideButton>
                  </Grid>
                ))}
                {!!affiliateComponent && (
                  <Grid item>
                    <SideButton
                      onClick={handleOpenAffiliateLinkModal}
                      variant="contained"
                      color="primary"
                      startIcon={<GroupAdd />}
                    >
                      CONVIDE UM AMIGO
                    </SideButton>
                  </Grid>
                )}
                {!!conferenceConfig.scheduleEnabled && (
                  <Grid item>
                    <span style={{ pointerEvents: 'none' }}>
                      <SideButton
                        variant="contained"
                        color="primary"
                        startIcon={<DateRange />}
                      >
                        CRONOGRAMA
                      </SideButton>
                    </span>
                    <SideBox>
                      <Box
                        width={1}
                        py={1}
                        dangerouslySetInnerHTML={{
                          __html: conferenceConfig.schedule,
                        }}
                      />
                    </SideBox>
                  </Grid>
                )}
              </Grid>
            </SideBar>
          </CenteredGrid>
        </>
      )}
      {!!warmupRoomComponent && (
        <WarmupRoomsModal warmupComponent={warmupRoomComponent} />
      )}

      <Footer />
    </Box>
  );
}
