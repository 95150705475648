import styled from 'styled-components';
import { Box, Grid, Typography } from '@material-ui/core';
import pointImg from '~/assets/images/reward-bg.png';

export const RankingBox = styled(Box)`
  background: transparent;

  .MuiTableCell-head {
    color: #707070;
  }

  .MuiTableCell-body {
    color: #333637;
    font-weight: bold;
  }

  .MuiTableBody-root {
    background: #edecec;
  }
`;

export const PointBox = styled(Box)`
  margin-top: 20px;
  margin-bottom: 66px;
`;

export const ImageBox = styled(Box)`
  background-image: url('${pointImg}');
  background-size: 100%;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 126px;
  width: 100%;
  max-width: 126px;
`;

export const TextGrid = styled(Grid)`
  padding-left: 28px !important;

  .MuiTypography-h4 {
    font-size: 26px;
    font-weight: bold;
  }
`;

export const NumberPoints = styled(Typography)`
  font-family: Orbitron;
  font-size: 36px;
  color: #2196f3;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const LabelPoints = styled(Typography)`
  font-family: Orbitron;
  font-size: 18px;
  color: #2196f3;
  font-weight: bold;
`;

export const TextTable = styled(Typography)`
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  text-transform: none;
  max-width: 500px;
  display: block;
  margin: 0 auto 49px;
`;

export const EmptyBox = styled(Box)`
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  text-transform: none;
  display: block;
  background: #edecec;
  padding: 40px 0;
  color: #707070;
`;
