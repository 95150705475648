import { call, put, all, takeLatest } from 'redux-saga/effects';
import api from '~/services/api';

import { Creators as InteractionActions, Types as InteractionTypes } from './ducks';

export function* interactionRequest({ payload }) {
  try {
    const { data } = yield call(api.post, '/interaction', payload);
    yield put(InteractionActions.success(data));
  } catch (error) {
    yield put(InteractionActions.failure(error));
  }
}

export default function* rootSaga() {
  return yield all([takeLatest(InteractionTypes.INTERACTION_REQUEST, interactionRequest)]);
}
