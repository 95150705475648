import React from 'react';
import { Document, Page, Text, Font, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import MultiAnswer from './multiAnswer';
import MultiChoice from './multiChoice';
import SingleChoice from './singleChoice';
import { styles } from './styles';

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

export default function LaunchPlanPDF({ launch, userProfile }) {
  const getContentType = (item) => {
    const componentsList = {
      MultiAnswer: <MultiAnswer key={item.id} questionItem={item} />,
      SingleChoice: <SingleChoice key={item.id} questionItem={item} />,
      MultiChoice: <MultiChoice key={item.id} questionItem={item} />,
    };

    return componentsList[item.kind] ? componentsList[item.kind] : <View />;
  };

  const launchPlanItems = launch.filter((launchItem) => !launchItem.pdf_button);

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>1º Encontro FL Insider 2021</Text>
        <Text style={styles.author}>{userProfile.name}</Text>
        {launchPlanItems.length ? (
          <>
            {launchPlanItems.map((question, index) => (
              <View key={question.id} break={index}>
                <Text style={styles.subtitle}>
                  {question.launch_plan.title}
                </Text>
                {question.launch_plan.items.map((item) => getContentType(item))}
              </View>
            ))}
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </>
        ) : (
          <Text style={styles.titleLaunchPlanNotFilled}>
            Atividades não preenchidas
          </Text>
        )}
        <Text style={styles.footerInfo} fixed>
          1º Encontro FL Insider 2021 - {userProfile.name}
        </Text>
        <Text style={styles.footerInfoCompany} fixed>
          FL INSIDER - Ignição Digital
        </Text>
      </Page>
    </Document>
  );
}

LaunchPlanPDF.propTypes = {
  launch: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
};
