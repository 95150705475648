import branca1 from '~/assets/images/branca1.png';
import branca2 from '~/assets/images/branca2.png';
import branca3 from '~/assets/images/branca3.png';
import branca4 from '~/assets/images/branca4.png';
import branca5 from '~/assets/images/branca5.png';
import branca6 from '~/assets/images/branca6.png';
import azul1 from '~/assets/images/azul1.png';
import azul2 from '~/assets/images/azul2.png';
import azul3 from '~/assets/images/azul3.png';
import verde1 from '~/assets/images/verde1.png';
import verde2 from '~/assets/images/verde2.png';
import verde3 from '~/assets/images/verde3.png';
import verde4 from '~/assets/images/verde4.png';
import marrom1 from '~/assets/images/marrom1.png';
import marrom2 from '~/assets/images/marrom2.png';
import marrom3 from '~/assets/images/marrom3.png';
import marrom4 from '~/assets/images/marrom4.png';
import preta1 from '~/assets/images/preta1.png';
import preta2 from '~/assets/images/preta2.png';
import preta3 from '~/assets/images/preta3.png';
import preta4 from '~/assets/images/preta4.png';
import preta5 from '~/assets/images/preta5.png';

const belts = [
  { label: 'branca1', path: branca1, text: 'FAIXA BRANCA', color: '#848483' },
  { label: 'branca2', path: branca2, text: 'FAIXA BRANCA', color: '#848483' },
  { label: 'branca3', path: branca3, text: 'FAIXA BRANCA', color: '#848483' },
  { label: 'branca4', path: branca4, text: 'FAIXA BRANCA', color: '#848483' },
  { label: 'branca5', path: branca5, text: 'FAIXA BRANCA', color: '#848483' },
  { label: 'branca6', path: branca6, text: 'FAIXA BRANCA', color: '#848483' },
  { label: 'azul1', path: azul1, text: 'FAIXA AZUL' },
  { label: 'azul2', path: azul2, text: 'FAIXA AZUL' },
  { label: 'azul3', path: azul3, text: 'FAIXA AZUL' },
  { label: 'verde1', path: verde1, text: 'FAIXA VERDE' },
  { label: 'verde2', path: verde2, text: 'FAIXA VERDE' },
  { label: 'verde3', path: verde3, text: 'FAIXA VERDE' },
  { label: 'verde4', path: verde4, text: 'FAIXA VERDE' },
  { label: 'marrom1', path: marrom1, text: 'FAIXA MARROM' },
  { label: 'marrom2', path: marrom2, text: 'FAIXA MARROM' },
  { label: 'marrom3', path: marrom3, text: 'FAIXA MARROM' },
  { label: 'marrom4', path: marrom4, text: 'FAIXA MARROM' },
  { label: 'preta1', path: preta1, text: 'FAIXA PRETA' },
  { label: 'preta2', path: preta2, text: 'FAIXA PRETA' },
  { label: 'preta3', path: preta3, text: 'FAIXA PRETA' },
  { label: 'preta4', path: preta4, text: 'FAIXA PRETA' },
  { label: 'preta5', path: preta5, text: 'FAIXA PRETA' },
];

export function GetBeltUrl(label) {
  const beltImage = belts.find((belt) => belt.label === label);

  return beltImage || { path: '', text: '' };
}
