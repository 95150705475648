import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import rewardBg from '~/assets/images/reward-bg.png';

export const ToastBox = styled.div`
  align-items: center;
`;

export const ToastIcon = styled.div`
  font-family: Orbitron;
  height: 60px;
  width: 60px;
  background-image: url('${rewardBg}');
  background-size: 100%;
  color: #2196f3;
  text-align: center;
  font-size: 12px;
  padding: 16px 7px;
  line-height: 1;
  margin-right: 20px;
`;

export const ToastText = styled(Typography)`
  color: #fff;
  font-size: 10px;
`;
