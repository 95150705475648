import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { Footer } from './styles';
import LogoDark from '~/assets/images/FLINSIDER_BRANCO.png';
import LogoWhite from '~/assets/images/Insider_Logo_Fundo_Claro.png';
// import LogoWhite from '~/assets/images/LOGO_PLAT10+_2.png';
// import LogoDark from '~/assets/images/LOGO_PLAT10+_1.png';

export default function ConferenceFooter() {
  const { darkMode } = useSelector((state) => state.darkMode);

  return (
    <Footer>
      <Box width={1} display="flex" justifyContent="center" alignItems="center">
        <img
          src={darkMode ? LogoDark : LogoWhite}
          alt="logo"
          className="logo"
        />
      </Box>
    </Footer>
  );
}
