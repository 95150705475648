import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { GridWelcome, WelcomeText } from '../../styles';
import { UserBelt } from './styles';
import { GetBeltUrl } from './importBeltUrl';

export default function UserInfo({ user, welcomeColor }) {
  const [belt, setBelt] = useState({ path: '', text: '' });

  useEffect(() => {
    // TODO: mudar para beltDegree no usuario
    setBelt(GetBeltUrl(user.belt));
  }, [user]);

  return (
    <GridWelcome container justify="start">
      <Grid item xs={12} sm={8}>
        <WelcomeText variant="h5" style={{ color: welcomeColor() }}>
          Bem-Vindo(a), <b>{user.name}</b>
        </WelcomeText>
      </Grid>
      <Grid item xs={12} sm={4}>
        <UserBelt belt={belt.path}>
          <WelcomeText
            variant="h5"
            style={{ color: belt.color || '#fff', paddingLeft: '4.5rem' }}
          >
            {belt.text}
          </WelcomeText>
        </UserBelt>
      </Grid>
    </GridWelcome>
  );
}

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  welcomeColor: PropTypes.func.isRequired,
};
