import styled from 'styled-components';

export const UserBelt = styled.div`
  background-image: url(${(props) =>
    props.belt ? props.belt : 'transparent'});
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 270px;
  width: 100%;
  margin: auto auto;
`;
