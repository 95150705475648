import styled from 'styled-components';
import {
  Box,
  Typography,
  Grid,
  Button,
  Tab,
  Icon as MaterialIcon,
  IconButton,
} from '@material-ui/core';
import vipBg from '~/assets/images/vip-bg.png';

export const CustomBox = styled(Box)`
  position: relative;
  background: ${(props) => (props.darkMode ? '#0D142C' : '#E1E8F1')};
  margin-bottom: 28px;
  padding: 14px 0 18px;
  height: 100%;
  min-height: 200px;
  max-height: 255px;

  .header-belts {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 20px;
  }

  @media (max-width: 960px) {
    padding: 20px 0;
    margin-bottom: 40px;
  }

  @media (max-width: 600px) {
    min-height: 275px;
  }
`;

export const CustomBoxContentGrid = styled(Grid)`
  @media (min-width: 600px) {
    transform: translateY(50%);
  }
`;

export const ExitButton = styled(Button)`
  color: ${(props) => (props.darkMode ? '#cccccc' : '#707070')};
  font-size: 1.9em;
`;

export const ChangeColorModeSwitcherGrid = styled(Grid)`
  background-color: #e0e0e0;
  border-radius: 10px;
  height: 3.4em;
`;
export const ChangeColorModeSwitcherIcon = styled(MaterialIcon)`
  background-color: #cccccc;
  align-content: center;
  width: 100%;
  height: 100%;
  padding: 0.1em;
  border-radius: 5px;
`;

export const CustomIconButton = styled(IconButton)`
  width: 100%;
  ${({ active }) =>
    !active &&
    `
    :hover {
      background-color: transparent !important;
    }
  `}
`;

export const DarkColorModeSwitcherIcon = styled(ChangeColorModeSwitcherIcon)`
  ${({ darkMode }) =>
    darkMode &&
    `
    border: none;
    opacity: 0.0;
  `}
`;

export const LightColorModeSwitcherIcon = styled(ChangeColorModeSwitcherIcon)`
  ${({ darkMode }) =>
    !darkMode &&
    `
    opacity: 0.0;
  `}
  ${({ darkMode }) =>
    darkMode &&
    `
    color: #cccccc;
    background-color: #272523;
  `}
`;

export const WelcomeText = styled(Typography)`
  @media (max-width: 600px) {
    font-size: 1.5rem;
    margin-bottom: 40px;
    padding: 0 16px;
    text-align: center;
  }
`;

export const GridWelcome = styled(Grid)`
  max-width: 1164px;
  margin: 0 auto;
  padding: 1rem;
`;

export const GridEventButton = styled(Grid)`
  max-width: 1164px;
  margin: 0 auto;
  padding: 1rem;
`;

export const Icon = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 42px;
  height: 50px;
  margin: 0 17px 0 0;
`;

export const DesafioText = styled(Typography)`
  color: white;
  font-weight: bold;
  font-size: 45px;

  @media (max-width: 960px) {
    margin-bottom: 40px;
  }
`;

export const VIPBox = styled(Grid)`
  p {
    font-family: Orbitron;
    color: #e1e1e1;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 1.4px;
  }

  @media (max-width: 960px) {
    margin-top: 20px;
  }
`;

export const VipButton = styled.div`
  padding: 0 41px;
  margin-top: 10px;
  position: relative;

  :before {
    content: '';
    position: absolute;
    width: 24px;
    height: 100%;
    left: 0;
    background-size: 100%;
    background-image: url('${vipBg}');
  }

  :after {
    content: '';
    position: absolute;
    width: 24px;
    height: 100%;
    right: 0;
    top: 0;
    background-size: 100%;
    transform: scaleX(-1);
    background-image: url('${vipBg}');
  }

  :hover {
    cursor: pointer;
  }

  p {
    font-size: 27px;
    font-weight: bold;
    color: #ffcb66;
    border: 1px solid #ffcb66;
    border-left: 0;
    border-right: 0;
    padding: 3px 10px;
  }
`;

export const CenteredGrid = styled(Grid)`
  max-width: 1164px;
  margin: 0 auto;
  .MuiBox-root-28 {
    text-align: start !important;
  }
`;

export const EventButton = styled(Button)`
  font-family: 'Orbitron';
  color: ${(props) =>
    props.fontcolor && props.fontcolor !== '#000000'
      ? props.fontcolor
      : '#ffffff'};
  text-transform: uppercase;
  border-radius: 10px;
  font-weight: bold;
  font-size: 18px;
  width: 100%;
  max-width: 100%;
  height: 63px;
  letter-spacing: 3.6px;
  background-color: ${(props) =>
    props.bgcolor && props.bgcolor !== '#cccc' ? props.bgcolor : '#289137'};
  :hover {
    background-color: ${(props) =>
      props.hvrcolor && props.hvrcolor !== '#cccc'
        ? props.hvrcolor
        : '#289137'};
  }
  @media (max-width: 600px) {
    font-size: 16px;
    height: auto;
    padding: 1rem 0 1rem 0;
  }

  .MuiButton-startIcon {
    color: ${(props) =>
      props.iconcolor && props.iconcolor !== '#000000'
        ? props.iconcolor
        : '#ffffff'};
    margin-right: 10px;
  }
  svg,
  img {
    font-size: 50px !important;
  }

  .material-icons {
    font-size: 50px !important;
  }
`;

export const TabButton = styled(Tab)`
  padding: 10px;
  display: ${(props) => (props.display ? '' : 'none')};
`;

export const MainContent = styled(Grid)`
  background-color: #faf8f8;
  border-radius: 15px;

  .PrivateTabIndicator-colorSecondary-11 {
    background-color: black;
  }

  .tab-icon {
    display: flex;
    align-items: center;

    span {
      font-weight: 700;
      font-size: 15px;

      @media (max-width: 35rem) {
        display: none;
      }
    }

    svg {
      font-size: 2.5rem;
      width: 1.3em;
    }
  }
`;

export const SideBar = styled(Grid)`
  @media (max-width: 960px) {
    margin-top: 40px;
  }
`;

export const SideButton = styled(Button)`
  width: 350px;
  height: 45px;
  font-family: Roboto;
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 20px;
  border-radius: 5px;
  color: ${(props) => (props.fontcolor ? props.fontcolor : '#ffffff')};
  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : props.theme.palette.primary.main};

  :hover {
    background-color: ${(props) =>
      props.hvrcolor ? props.hvrcolor : '#000000'};
  }

  .MuiButton-startIcon {
    color: ${(props) => (props.iconcolor ? props.iconcolor : '#2196f3')};
    margin-right: 6px;
  }

  svg,
  img {
    color: #2196f3;
    font-size: 30px !important;
  }

  .material-icons {
    font-size: 30px !important;
  }
`;

export const SideBox = styled(Box)`
  background: #f0f0f0;
  width: 350px;
  min-height: 158px;
  margin-top: -20px;
  padding: 9px 14px;

  p {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const HrBarGrid = styled(Grid)`
  display: flex;
  justify-content: center;

  hr {
    color: #d5d5d5;
  }
`;

export const FooterGrid = styled.div`
  bottom: 0 !important;
  position: absolute !important;
  width: 100vw;
`;

export const Footer = styled.div`
  position: relative;
  width: 100%;
  padding: 20rem 0 5rem 0;

  .logo {
    max-width: 300px;
  }

  @media (max-width: 600px) {
    padding: 15rem 0 5rem 0;
  }
`;

export const StyledQuestionDate = styled(Typography)`
  color: #3fb8ed;
  font-size: 14px;
`;

export const CenteredFlexBox = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
`;
