import styled from 'styled-components';
import { Button, Grid, Box } from '@material-ui/core';
import circleDark from '~/assets/images/INSIDER_BG_DASHBOARD_ENCONTRO_LOGO_SEM_R_ESCURO.png';
import circleWhite from '~/assets/images/INSIDER_BG_DASHBOARD_ENCONTRO_LOGO_SEM_R_CLARO.png';
import bilheteDark from '~/assets/svgs/bilhete_dark.svg';
import bilheteWhite from '~/assets/svgs/bilhete_white.svg';

export const Container = styled(Grid)`
  background-color: ${(props) => (props.darkMode ? '#0D142C' : '#e4e7f1')};
  background-image: url(${(props) =>
    props.darkMode ? circleDark : circleWhite});
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;
  height: 100%;

  @media (max-width: 600px) {
    .mobilePadding {
      padding: 85px 0;
    }
  }
`;

export const ItemBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const LoginForm = styled(Box)`
  background-image: url(${(props) =>
    props.darkMode ? bilheteDark : bilheteWhite});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 85px;
  padding-right: 50px;
  max-width: 700px;
  margin: auto;

  .forgotMyToken {
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    color: ${(props) => (props.darkMode ? '#110c2b00' : '#5b5b5b')};
  }
  @media (max-height: 640px) {
    padding-left: 140px;
    padding-right: 140px;
  }

  @media (max-width: 600px) {
    padding-left: 40px;
    padding-right: 40px;

    .FormGridItems {
      padding-bottom: 0px;
      padding-top: 0px;
    }

    .forgotMyToken {
      font-size: 14px;
    }
  }

  @media (max-width: 450px) {
    background: ${(props) => (props.darkMode ? '#110c2b' : '#e4e7f1')};
  }

  .textDarkOrWhite {
    color: ${(props) => (props.darkMode ? '#efefee' : '#5b5b5b')};
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
`;

export const SubmitButton = styled(Button)`
  background-color: ${(props) => (props.darkMode ? '#787878' : '#e0e0e0')};
  color: ${(props) => (props.darkMode ? '#efefee' : '#5b5b5b')};
  width: 210px;
  font-size: 1.3rem;

  @media (max-width: 600px) {
    width: 150px;
    font-size: 1.1rem;
    height: 35px;
  }

  svg {
    font-size: 35px !important;
  }

  .MuiButton-label {
    justify-content: flex-start;
    margin-left: 15px;
  }

  &:hover {
    background-color: ${(props) => (props.darkMode ? '#272523' : '#afafaf')};
  }
`;

export const InputGrid = styled(Grid)`
  .inputDarkLightMode {
    background-color: ${(props) => (props.darkMode ? '#787878' : '#e0e0e0')};
    border: 0;

    input {
      color: ${(props) => (props.darkMode ? '#efefee' : '#5b5b5b')};
      font-weight: bold;
      .MuiIconButton-root {
        color: ${(props) => (props.darkMode ? '#efefee' : '#5b5b5b')};
      }
    }
  }

  .adornmentIcon {
    color: ${(props) => (props.darkMode ? '#efefee' : '#5b5b5b')};
  }

  p {
    background-color: ${(props) => (props.darkMode ? '#848483' : '#cccccc')};
    font-size: 16px;
    color: ${(props) => (props.darkMode ? '#efefee' : '#5b5b5b')};
    margin: 0;
  }
`;
