import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { ArrowRightAlt, Visibility, VisibilityOff } from '@material-ui/icons';
import { Creators as AuthActions } from '~/store/modules/auth/ducks';

import {
  Container,
  LoginForm,
  ItemBox,
  SubmitButton,
  InputGrid,
} from './styles';

import ValidationSchema from './index.schema';
import DarkModeSwitch from '~/components/DarkModeSwitch';
import ForgotToken from './components/ForgotToken';
import { Creators as ModeColor } from '~/store/modules/darkMode/ducks';

function Signin() {
  const [showToken, setShowToken] = useState(false);
  const { darkMode } = useSelector((state) => state.darkMode);
  const [forgotModalOpen, setForgotModalOpen] = useState(false);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      token: '',
    },
    validationSchema: ValidationSchema,
    validateOnChange: true,
    onSubmit: ({ token }) => {
      dispatch(AuthActions.signInRequest({ token }));
    },
  });

  const handleDarkMode = () => {
    if (darkMode) {
      dispatch(ModeColor.setLightMode());
    } else {
      dispatch(ModeColor.setDarkMode());
    }
  };

  const redirectToForgot = () => setForgotModalOpen(true);
  const handleCloseModal = () => setForgotModalOpen(false);

  return (
    <>
      <ForgotToken
        isOpen={forgotModalOpen}
        handleCloseModal={handleCloseModal}
        darkMode={darkMode}
      />
      <Container container darkMode={darkMode}>
        <Grid item xs={12}>
          <div style={{ height: '100px' }} />
        </Grid>
        <Grid item xs={12}>
          <LoginForm
            container
            direction="column"
            justify="center"
            alignItems="center"
            darkMode={darkMode}
          >
            <Grid item md={12} xs={12} className="mobilePadding">
              <Grid
                container
                direction="column"
                alignItems="flex-start"
                spacing={3}
              >
                <Grid item className="FormGridItems">
                  <Typography darkMode={darkMode} className="textDarkOrWhite">
                    Para iniciar sua experiência digite sua chave de acesso
                    disponível no seu Ticket virtual
                  </Typography>
                </Grid>
                <InputGrid item darkMode={darkMode}>
                  <TextField
                    fullWidth
                    className="inputDarkLightMode"
                    placeholder="Digite a sua chave de acesso"
                    variant="outlined"
                    value={formik.values.token}
                    onChange={formik.handleChange}
                    name="token"
                    type={showToken ? 'text' : 'password'}
                    error={formik.errors.token}
                    helperText={formik.errors.token}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle token visibility"
                            onClick={() => setShowToken(!showToken)}
                            className="adornmentIcon"
                          >
                            {showToken ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputGrid>
                <Grid item xs={8}>
                  <SubmitButton
                    onClick={formik.submitForm}
                    disabled={
                      formik.dirty && !formik.errors.token && !formik.isValid
                    }
                    darkMode={darkMode}
                    endIcon={<ArrowRightAlt />}
                  >
                    Entrar
                  </SubmitButton>
                  <Typography
                    onClick={() => redirectToForgot()}
                    className="forgotMyToken"
                    darkMode={darkMode}
                  >
                    Esqueci o meu token
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </LoginForm>
        </Grid>
        <Grid item xs={12}>
          <ItemBox>
            <DarkModeSwitch
              darkMode={darkMode}
              handleDarkMode={() => handleDarkMode()}
            />
          </ItemBox>
        </Grid>
      </Container>
    </>
  );
}

export default Signin;
