import { Grid, Icon as MaterialIcon, IconButton } from '@material-ui/core';
import styled from 'styled-components';

export const ChangeColorModeSwitcherGrid = styled(Grid)`
  background-color: ${(props) => (props.darkMode ? '#848483' : '#cccccc')};
  border-radius: 10px;
  height: 3.4em;
`;
export const ChangeColorModeSwitcherIcon = styled(MaterialIcon)`
  background-color: ${(props) => (props.darkMode ? 'black' : '#e8e8e8')};
  align-content: center;
  width: 100%;
  height: 100%;
  padding: 0.1em;
  border-radius: 5px;
  color: #000;
`;

export const CustomIconButton = styled(IconButton)`
  width: 100%;
  ${({ active }) =>
    !active &&
    `
    :hover {
      background-color: transparent !important;
    }
  `}
`;

export const DarkColorModeSwitcherIcon = styled(ChangeColorModeSwitcherIcon)`
  ${({ darkMode }) =>
    darkMode &&
    `
    border: none;
    opacity: 0.0;
  `}
`;

export const LightColorModeSwitcherIcon = styled(ChangeColorModeSwitcherIcon)`
  ${({ darkMode }) =>
    !darkMode &&
    `
    opacity: 0.0;
  `}
  ${({ darkMode }) =>
    darkMode &&
    `
    color: #cccccc;
    background-color: #272523;
  `}
`;
